import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [{
  path: '/',
  component: Home,
  redirect: '/index',
  children: [{
  		path: '/index', //商城
  		component: () => import( /* webpackChunkName: "tab" */ '../views/page/index.vue')
  	},
  	{
  		path: '/walk', //走进我们
  		component: () => import( /* webpackChunkName: "tab" */ '../views/page/walk.vue')
  	},
  	{
  		path: '/brand', //品牌
  		component: () => import( /* webpackChunkName: "tab" */ '../views/page/brand.vue')
  	},
  	{
  		path: '/join_user', //加入我们
  		component: () => import( /* webpackChunkName: "tab" */ '../views/page/join_user.vue')
  	},
	{
		path: '/download', //下载app
		component: () => import( /* webpackChunkName: "tab" */ '../views/page/download.vue')
	},
	{
		path: '/culture', //企业文化
		component: () => import( /* webpackChunkName: "tab" */ '../views/page/culture.vue')
	},
	{
		path: '/dynamic', //最新动态
		component: () => import( /* webpackChunkName: "tab" */ '../views/page/dynamic.vue')
	},
	{
		path: '/health', //走进江康
		component: () => import( /* webpackChunkName: "tab" */ '../views/page/health.vue')
	},
	{
		path: '/article', //走进江康
		component: () => import( /* webpackChunkName: "tab" */ '../views/page/article.vue')
	},
	{
		path: '/detail', //商品
		component: () => import( /* webpackChunkName: "tab" */ '../views/page/detail.vue')
	},
  ]
}]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
