<template>
	<div id="app" v-cloak>
		<!-- 头部导航 -->
		<div class="header">
			<div class="head-in">
				<div class="showNav" @click="showList=!showList"></div>
				<div class="logo hand" @click="back"><img src="../../public/images/logo.png" alt=""></div>
				<div class="nav">
					<div class="hand" v-for="(nav,index) in navList" @mouseenter="start(index)"
						@click="avtiveNav(index,nav.path)" :class="navStatus==index?'active':''">
						{{nav.navNmae}}<i v-if="index==1" :class="show?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
					</div>
				</div>
			</div>
			<div class="show" v-if="show" @mouseenter="startShow()" @mouseleave="end()">
				<div @click="healthPath(index,item.path)" :class="navsStatus==index?'green':''"
					v-for="(item,index) in healthList">{{item.name}}</div>
			</div>
		</div>
		<!-- 中间内容 -->
		<div class="padding-box">
			<router-view />
		</div>
		<!-- 返回顶部按钮 -->
		<div class="top" @click="backTop" v-if="backTopStatus">
			<img src="../../public/images/to-top.png" alt="">
		</div>
		<!-- 页脚 -->
		<div class="footer">
			<img class="footer_back" src="../../public/images/footer_back.png" alt="">
			<div class="footer-box">
				<!-- 四张图 -->
				<div class="chenglai">
					<div>诚赉健康</div>
					<div class="border">
						<div class="code-img">
							<div class="code hand"><img src="../../public/images/gzh.jpg" alt=""></div>
							<div class="hand">公众号</div>
						</div>
						<div class="code-img hand">
							<div class="code"><img src="../../public/images/app.png" alt=""></div>
							<div class="hand">值班长APP</div>
						</div>
						<!-- <div class="code-img hand">
							<div class="code"></div>
							<div class="hand">官方微博</div>
						</div>
						<div class="code-img hand">
							<div class="code"></div>
							<div class="hand">巨壳APP</div>
						</div> -->
					</div>
				</div>
				<!-- 快捷入口 -->
				<div class="chenglai center">
					<div>快捷入口</div>
					<div class="flex">
						<div class="hand" @click="routerData('index',0)">首页</div>
						<div>
							<div>走进诚赉健康</div>
							<div class="hand" @click="routerData('culture',1)">企业文化</div>
							<div class="hand" @click="routerData('walk',1)">关于我们</div>
							<div class="hand" @click="routerData('dynamic',1)">最新动态</div>
						</div>
						<div class="hand" @click="routerData('health',2)">健康专栏</div>
						<div class="hand" @click="routerData('brand',3)">品牌</div>
						<div class="hand" @click="routerData('join_user',4)">加入我们</div>
					</div>
				</div>
				<!-- 联系我们 -->
				<div class="chenglai">
					<div>联系我们</div>
					<div class="us_box">
						<div>
							<div>24小时热线</div>
							<div>400-687-6889</div>
						</div>
						<div>
							<div>24小时服务邮箱</div>
							<div>ccchenglaijiankang@163.com</div>
						</div>
					</div>
				</div>
			</div>
			<div class="min-footer">
				<div>
					<div @click="imgShow(1)">公众号</div>
					<div @click="imgShow(2)">值班长app</div>
					<div><a href="tel:400-687-6889">24小时热线</a></div>
				</div>
			</div>
			<div class="footer-text hand">Copyright 2021© 诚赉健康（吉林）产业有限公司All Rights Reserved ICP备案号</div>
		</div>
		<div class="dialog" v-if="diaImg">
			<div class="dia-title">{{imgStatus==1?'公众号':'值班长app'}}</div>
			<div class="close" @click="diaImg=false"><i class="el-icon-circle-close"></i></div>
			<img :src="imgStatus==1?img1:img2" alt="">
		</div>
		<el-drawer :visible.sync="showList" :direction="direction" size="100%" :show-close="false" :modal="false">
			<div class="nav-list">
				<div @click="navListRouter(item.path,index)" :class="minColor==index?'green':''" v-for="(item,index) in navMinList">{{item.name}}</div>
			</div>
		</el-drawer>
		<a href="tencent://message/?uin=527856286&Site=&Menu-=yes">
			<div class="tel">
				<img src="../../public/images/qq.png" alt="">
			</div>
		</a>
	</div>
</template>

<script>
	import img1 from '../../public/images/gzh.jpg';
	import img2 from '../../public/images/app.png';
	export default {
		data() {
			return {
				navMinList:[
					{
						name:'首页',
						path:'index',
					},
					{
						name:'企业文化',
						path:'culture',
					},
					{
						name:'关于我们',
						path:'walk',
					},
					{
						name:'最新动态',
						path:'dynamic',
					},
					{
						name:'健康专栏',
						path:'health',
					},
					{
						name:'品牌',
						path:'brand',
					},
					{
						name:'加入我们',
						path:'join_user',
					},
					{
						name:'下载APP',
						path:'download',
					},
				],
				minColor:0,
				img1,
				img2,
				imgStatus: 1,
				diaImg: false,
				direction: 'rtl',
				backTopStatus: false,
				show: false,
				showList: false,
				navStatus: 0,
				navsStatus: -1,
				healthList: [
					{
						name: '企业文化',
						path: 'culture',
					},
					{
						name: '关于我们',
						path: 'walk',
					},
					{
						name: '最新动态',
						path: 'dynamic',
					},
				],
				navList: [{
						navNmae: '首页',
						path: '/index',
					},
					{
						navNmae: '走进诚赉健康',
					},
					{
						navNmae: '健康专栏',
						path: '/health',
					},
					{
						navNmae: '品牌',
						path: '/brand',
					},
					{
						navNmae: '加入我们',
						path: '/join_user',
					},
					{
						navNmae: '下载APP',
						path: '/download',
					},
				],
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true)
		},
		methods: {
			navListRouter(path,index){
				this.$router.push(path);
				this.showList=false;
				this.minColor=index;
				sessionStorage.minColor = this.minColor;
			},
			imgShow(status) {
				this.diaImg = true;
				this.imgStatus = status;
			},
			handleScroll() {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				if (scrollTop >= 700) {
					this.backTopStatus = true
				} else {
					this.backTopStatus = false
				}
			},
			backTop() {
				var timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = -20;
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 1)
			},
			routerData(path, num) {
				this.$router.push(path);
				sessionStorage.navStatus = num;
				this.navStatus = num;
			},
			healthPath(index, path) {
				this.$router.push(path);
				sessionStorage.navStatus = 1;
				this.navStatus = 1;
				this.navsStatus = index;
			},
			startShow() {
				this.show = true;
			},
			start(index) {
				if (index == 1) {
					this.show = true;
				}
			},
			end() {
				this.show = false;
			},
			back() {
				this.$router.push('index');
				this.navStatus = 0;
				sessionStorage.navStatus = 0;
				this.minColor = 0;
				sessionStorage.minColor = 0;
			},
			avtiveNav(index, path) {
				if (index != 1) {
					this.navStatus = index;
					sessionStorage.navStatus = this.navStatus;
					this.$router.push(path);
					this.navsStatus = -1;
				}
			}
		},
		created() {
			if (sessionStorage.navStatus == undefined) {
				this.navStatus = 0;
				this.minColor=0;
			} else {
				this.navStatus = sessionStorage.navStatus;
				this.minColor = sessionStorage.minColor
			}
		}
	}
</script>

<style scoped="scoped">
	.tel {
		bottom: 230px;
		font-size: 26px;
		padding-bottom: 4px;
	}
	
	.tel img {
		width: 52%;
		padding-top: 4px;
	
	}
	a{
		color: #fff;
		text-decoration: none;
	}
	/deep/ .el-drawer {
		height: calc(100vh - 40px) !important;
		bottom: 0 !important;
		top: 40px !important;
	}

	.dia-title {
		position: absolute;
		width: 100%;
		text-align: center;
		top: 24px;
		font-size: 16px;
		color: #fff;
	}

	.close {
		position: absolute;
		right: 16px;
		top: 24px;
		color: #fff;
		font-size: 18px;
	}

	.dialog {
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 3000;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.6);
		display: flex;
		justify-content: center;
		align-items: center;

	}

	/deep/.el-drawer__header {
		display: none;
	}

	.nav-list {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	.nav-list>div {
		line-height: 40px;
	}

	.dialog img {
		width: 80%;
	}

	.min-footer,
	.showNav {
		display: none;
	}

	.top {
		position: fixed;
		right: 50px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		z-index: 2000;
		background: rgba(0, 0, 0, 0.6);
		bottom: 158px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.top img {
		width: 70%;
		height: 70%;
		display: block;
	}

	[v-cloak] {
		display: none;
	}

	.green {
		color: #1CA167;
	}

	.padding-box {
		padding-top: 80px;
	}

	.show {
		position: absolute;
		bottom: -40px;
		background-color: #fff;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		z-index: 999;
		padding-left: 180px;
	}

	.show>div {
		width: 120px;
		text-align: right;
	}

	.show>div:hover {
		color: #1CA167;
		cursor: pointer;
	}

	.footer-text {
		position: absolute;
		bottom: 38px;
		width: 100%;
		text-align: center;
		font-size: 18px;
	}

	.center {
		position: relative;
		margin: 0 60px 0 60px;
	}

	.hand {
		cursor: pointer;
	}

	.flex {
		display: flex;
		font-size: 18px;
	}

	.us_box {
		font-size: 24px;
	}

	.us_box>div {
		margin-bottom: 32px;
	}

	.us_box>div>div {
		margin-bottom: 22px;
	}

	.flex>div {
		margin-right: 24px;
	}

	.flex>div>div {
		margin-bottom: 24px;
		text-align: center;
	}

	.code-img {
		margin-right: 18px;
		text-align: center;
		margin-bottom: 16px;
	}

	.chenglai {
		padding-top: 80px;
	}

	.code {
		width: 142px;
		height: 142px;
		background-color: #999;
		margin-bottom: 10px;
	}

	.code img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.chenglai>div:nth-child(1) {
		font-weight: bold;
		font-size: 24px;
		margin-bottom: 26px;
	}

	.border {
		display: flex;
		flex-wrap: wrap;
		width: 370px;
		font-size: 20px;
		border-right: 1px solid #fff;
		/* height: 366px; */
	}

	.footer-box {
		position: absolute;
		width: 1280px;
		top: 0;
		left: 50%;
		margin-left: -640px;
		display: flex;
	}

	.footer {
		position: relative;
		color: #fff;
		z-index: 888;
	}

	.footer_back {
		width: 100%;
		height: 460px;
		display: block;
	}

	.logo {
		width: 180px;
	}

	.logo img {
		width: 100%;
	}

	.active {
		position: relative;
		color: #1CA167;
	}

	.active::after {
		position: absolute;
		width: 40px;
		left: 50%;
		margin-left: -20px;
		height: 2px;
		background-color: #1CA167;
		bottom: 20px;
		content: '';
		animation: myfirst 0.5s;
	}

	@-webkit-keyframes myfirst {
		0% {
			background: #1CA167;
			width: 4px;
		}


		100% {
			background: #1CA167;
			width: 40px;
		}
	}
	.tel{
		display: none;
	}
	.nav {
		display: flex;
		font-size: 18px;
		color: #666;
		height: 100%;
		line-height: 1;
	}

	.nav>div {
		margin-left: 50px;
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.nav>div:hover {
		color: #1CA167;
	}

	.head-in {
		height: 100%;
		width: 1280px;
		position: absolute;
		left: 50%;
		margin-left: -640px;
		background-color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 900;
	}

	.header {
		width: 100%;
		height: 80px;
		position: fixed;
		left: 0;
		background-color: #fff;
		z-index: 900;
		top: 0;
	}

	@media (max-width: 500px) {
		.header {
			width: 100%;
			height: 40px;
		}

		.head-in {
			width: 100% !important;
		}

		.padding-box {
			padding-top: 40px;
		}

		.footer {
			width: 100%;
		}

		.footer-box {
			display: none;
		}

		.footer_back {
			height: auto;
		}

		.head-in {
			display: flex;
			flex-direction: column;
			left: 0;
			margin: 0;
		}

		.logo img {
			position: absolute;
			width: 80px;
			top: 10px;
			left: 12px;
		}

		.nav {
			width: 100%;
			display: none !important;
		}

		.nav>div {
			margin: 0 !important;
		}

		.showNav {
			display: block !important;
			position: absolute;
			right: 12px;
			top: 8px;
			height: 18px;
			width: 16px;
			border-bottom: 2px #999 solid;
			padding-bottom: 8px;
		}

		.showNav::before {
			position: absolute;
			top: 4px;
			content: '';
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #999;
		}

		.showNav::after {
			position: absolute;
			top: 10px;
			content: '';
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #999;
		}

		.showList {
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			color: #fff;
			right: 12px;
			top: 40px;
			padding: 12px;
			text-align: center;
			border-radius: 8px;
			font-size: 14px;
		}

		.showList div {
			line-height: 18px;
		}

		.footer-text {
			font-size: 12px;
			bottom: 16px;
			line-height: 18px;
		}

		.min-footer {
			display: block !important;
			position: absolute;
			width: 100%;
			top: 8vw;
			box-sizing: border-box;
			padding: 0 12px 12px 12px;
		}

		.min-footer>div {
			display: flex;
			justify-content: space-between;
			text-align: center;
			font-size: 14px;
			line-height: 16px;
			height: 16px;
		}

		.min-footer>div>div {
			flex: 1;
		}

		.min-footer>div>div:nth-child(2) {
			border-left: 1px solid #fff;
			border-right: 1px solid #fff;
		}
		.top{
			display: none;
		}
	}
</style>
